<template>
    <w-data-table
        v-if="collection.data"
        outlined
        searchable
        hide-default-header
        :loading="loading"
        :headers="headers"
        :items="collectionData"
        :filter-by="filterBy"
        :row-click="onRowClick"
    >
        <!-- actions -->
        <template #[`item.actions`]>
            <w-icon-next color="primary"/>
        </template>
    </w-data-table>
</template>

<script>
import { getCoursesCollection } from '@/apps/school/api/courses'

export default {
    name: 'DepartmentsCourses',
    props: {
        uuid: String
    },
    computed: {
        api() {
            return getCoursesCollection
        },
        collectionData() {
            return this.collection.data.map(item => {
                return item
            })
        },
        headers() {
            return [
                {
                    text: this.$trans('Course'),
                    value: 'title'
                }, {
                    value: 'actions',
                    align: 'right',
                    sortable: false
                }
            ]
        }
    },
    data() {
        return {
            loading: false,
            collection: [],
            filterBy: ['title', 'teacher_name', 'group_title']
        }
    },
    mounted() {
        this.$nextTick(async () => {
            await this.fetchCourses()
        })
    },
    methods: {
        async fetchCourses(payload) {
            this.loading = true

            payload = payload || {}
            payload.department = this.uuid

            await this.api(payload)
                .then(response => {
                    this.collection = response.data
                    this.loading = false
                })
        },
        onRowClick(item) {
            this.$router.push({
                name: 'departments.courses.details',
                params: { cuuid: item.uuid, uuid: this.uuid }
            }).catch(() => {
                // console.log(error)
            })
        }
    }
}
</script>

<style lang="scss">

</style>
